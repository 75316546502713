.rbc-allday-cell, .rbc-toolbar {
    display: none !important;
}
.rbc-button-link {
    width: 100%;
    pointer-events: none;
}
.rbc-time-view {
    overflow: hidden;
    border: 0 !important;
    border-left: 1px solid #E4E1E0 !important;
    border-top: 1px solid #E4E1E0 !important;
    border-bottom: 1px solid #E4E1E0 !important;
    border-radius: 8px;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}
.rbc-timeslot-group {
    min-height: 72px !important
}
.rbc-event {
    max-width: 180px;
    display: inline-table !important;
}
.rbc-event, .rbc-day-slot .rbc-background-event {
    border: 0 !important;
    padding: 16px !important;
    background-color: #3DBA71 !important;
}
.rbc-event-label {
    margin-bottom: 8px !important;
}
.rbc-label {
    font-weight: 500;
    color: #71717A !important;
}
.rbc-header {
   border-bottom: 0 !important;
}
.rbc-time-header-content {
    border-left: 0 !important;
}
.rbc-day-slot .rbc-events-container {
    margin-right: 0 !important;
}
.rbc-time-gutter .rbc-timeslot-group {
    border: 0 !important;
}
.rbc-time-content {
    border-top: 0 !important;
}
.rbc-time-content > * + * > * {
    border-left: 0 !important;
    border-right: 1px solid #ddd;
}
.rbc-time-view {
    border: 0 !important;
}
tspan {
    font-weight: 600;
    color: #909090;
}
.rbc-day-slot .rbc-event-content {
    line-height: 20px;
}
/* simple calendar */

.react-calendar, .recharts-wrapper {
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}
.react-calendar__month-view__weekdays {
    padding-top: 16px;
    padding-bottom: 16px;
}
.react-calendar__month-view__weekdays__weekday {
    font-weight: 600;
    color: #5D5B65;
}
.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}
.react-calendar__month-view__days__day {
    background: none;
    border: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 14px;
    color: #5D5B65;
    padding: 0;
    width: 100%;
    position: relative;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-calendar__month-view__days__day:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
.react-calendar__month-view__days__day--neighboringMonth {
    opacity: .5;
}
.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
    display: none;
}
.react-calendar__navigation {
    display: flex;
}
.react-calendar__tile--now {
    background: #3DBA71;
    color: white;
    font-weight: 600;
    border-radius: 50%;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    aspect-ratio: 1;
}
.react-calendar__tile--now:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
.react-calendar__navigation__label, .react-calendar__navigation__arrow {
    font-weight: 600;
    background: none;
    border: 0;
}
.react-calendar__navigation__label {
    pointer-events: none;
}
.react-calendar__navigation__arrow {
    font-size: 16px;
    cursor: pointer;
}
.react-calendar__month-view__days__day {
    cursor: pointer;
}

.react-calendar__tile--active {
    background: gray;
    color: white;
    font-weight: 600;
    border-radius: 50%;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    aspect-ratio: 1;
}