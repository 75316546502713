
.google-places-autocomplete__input {
    width: 100%;
    height: auto;
    padding: .5rem 1rem;
    font-size: .95rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #becad6;
    font-weight: 400;
    will-change: border-color,box-shadow;
    border-radius: .375rem;
    box-shadow: none;
    transition: box-shadow 250ms cubic-bezier(.27,.01,.38,1.06),border 250ms cubic-bezier(.27,.01,.38,1.06);
}

.google-places-autocomplete__suggestions-container {
    background-color: #ffffff;
    border-radius: 5px;
    color: #212529;
    height: 100px;
    overflow-y: scroll;
}

.google-places-autocomplete__suggestions-container > div {
    padding: 10px;
}