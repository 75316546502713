.grecaptcha-badge {
    visibility: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-time-range-wrapper .error {
  margin-top: 8px;
  color: red;
  font-size: 80%;
}
#react-time-range {
  display: flex;
}
#react-time-range, #react-time-range .component {
  padding: 0 !important;
}
#react-time-range .label {
  display: none;
}
#end-component {
  margin-left: 16px;
}
#react-time-range select {
  border-color: #e1e1e1 !important;
  background: white !important;
  height: 40px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-radius: 6px !important;
  font-size: 16px;
  font-weight: 600;
}
.breaks-popover {
  transform: inherit !important;
  left: 50% !important;
  margin-left: -200px !important;
  top: 32px !important;
}
.files_drop_zone {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  box-sizing: border-box;
  border: 0 !important;
  background: #E4E1E0;
  border-radius: 6px;
}
body > li {
  z-index: 9999999;
}